/* -------------------------------------------------------------------------- *
 * Button Styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.button {
  align-items: center;
  border: 2px solid;
  border-radius: 6px;
  display: inline-flex;
  font-weight: bold;
  line-height: 1.1;
  margin: 0 10px 1rem 0;
  padding: 10px 15px 10px 20px;
  text-decoration: none;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &--primary {
    background-color: palette('black');
    border-color: palette('black');
    color: white;
  }

  &--secondary {
    background-color: transparent;
    border-color: palette('black');
    color: palette('black');
  }

  &--tertiary {
    border: 0;
    color: palette('black');
    padding: 0;
    text-decoration: underline;
  }
}
