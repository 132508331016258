/* -------------------------------------------------------------------------- *
 * Placeholder Classes for Text *
 * -------------------------------------------------------------------------- */

@use '../baseline' as *;

// Already in use for this project
%largeHeading {
  font-size: toRem(50px);
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 24px;
  @media (max-width: 800px){
    font-size: toRem(34px);
  }
  @media (max-width: 500px){
    font-size: toRem(30px);
  }
}

%mediumHeading {
  font-size: toRem(42px);
  font-weight: bold;
  line-height: 1.1;
}

// Already in use for this project
%defaultHeading {
  font-size: toRem(36px);
  font-weight: bold;
  line-height: 1.1;
}

// Already in use for this project
%mediumSmallHeading {
  font-size: toRem(28px);
  font-weight: bold;
  line-height: 1.1;
}

// Already in use for this project
%smallHeading {
  font-size: toRem(20px);
  font-weight: bold;
  line-height: 1.1;
}

%defaultlabel {
  font-size: toRem(16px);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

%posted {
  color: palette('blue');
  text-transform: uppercase;
  font-weight: fontWeight(medium);
  line-height: 20px;
}

%textLink {
  color: palette('green');
  text-decoration: underline;
  &:hover {
    color: palette('green', 'light');
  }
}

// Inline IMG or SVG element
%icon {
  display: inline-block;
  fill: currentColor;
  margin: 0 10px;
  vertical-align: middle;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
