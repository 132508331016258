/* -------------------------------------------------------------------------- *
 * CTA Styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.cta {
  align-items: center;
  background-color: palette('black', 'lightest');
  color: white;
  display: flex;
  margin: 2rem 0;
  padding: var(--gutter);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__feature {
    flex-basis: calc(50% - var(--gutter));
    margin-right: var(--gutter);

    @media (max-width: toEm(800px)) {
      margin-bottom: var(--gutter);
      margin-right: 0;
    }
  }

    &__featureImg {
      display: block;
    }

  &__content {
    flex-basis: 50%;
    flex-grow: 1;
  }

  &__fullWidthCta {
    &--teal {
      background-color: palette('teal');
    }
    &--dark {
      background: url(/images/pattern-grey-swirls.png) palette('black');
    }
  }

  &__fullWidthFormCta {
    &--teal {
      background-color: palette('teal');
    }
    &--dark {
      background: url(/images/pattern-grey-swirls.png) palette('black');
    }
  }

  &__heading {
    @extend %defaultHeading;
    margin-bottom: 24px;
    color: white;
    &:after {
      content: "";
      display: block;
      width: 90px;
      height: 6px;
      margin-top: 20px;
      background-color: #fee938;
    }
  }

  &__container {
    @extend %container;
    display: flex;
    justify-content: flex-end;
    gap: 70px;
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 1050px) {
      display: block;
    }
  }
    &__spacer {
      flex: 0 0 290px;
      @media (max-width: 1050px) {
        display: none;
      }
    }
    &__flex{
      max-width: 880px;
      padding-left: 65px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-right: var(--gutter);
      @media (max-width: 1120px){
        padding-left: 0;
        padding-right: 0;
        display: block;
      }
    }
    &__externalContainer {
      color: white;
      width: 100%;
      padding-left: 65px;
      padding-right: var(--gutter);
      @media (max-width: 1120px){
        padding-left: 0;
        padding-right: 0;
        display: block;
      }
      .freeform-row  {
        max-width: 880px;
        width: 100%;
        margin: 0;
        display: flex;
        gap: var(--gutter);
        @media (max-width: 1120px){
          display: block;
          padding-right: 0;
        }
      }
      .cta__flex {
        padding-left: 0;
        @media (max-width: 1120px){
          .cta__content {
            padding-right: 0;
          }
        }
      }
      .freeform {
        .freeform-column {
          margin: 0;
          width: 100%;
          max-width: 500px;

          @media (max-width: 1120px){
            display: block;
            padding-right: 0;
            max-width: 600px;
          }
        }
      }
    }
      &__content {
        color: white;
        flex: 0 0 500px;
        padding-right: var(--gutter);
        margin-bottom: 24px;
      }
      &__buttonGroup {
        color: white;
        flex: 1;
      }

  @media (max-width: toEm(800px)) {
    display: block;
  }
}
