/* -------------------------------------------------------------------------- *
 * Main Header Nav Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.headerNav {
  position: relative;
  z-index: zIndex(headerNav);
  &__container {
    @extend %container;
    max-width: 1446px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
  }

  &__topSection {
    display: flex;
    align-items: center;
  }

  &__mainLogo {
  }

  &__logoWrapper {
    flex: 0 1 33%;
  }

  &__searchFormWrapper {
    flex: 1 1 33%;
  }
    &__searchForm {
      width: 100%;
      max-width: 300px;
      &--mobile {
        max-width: 400px;
        padding-left: var(--gutter);
        padding-right: var(--gutter);
        padding-bottom: 30px;
        .headerNav__formInputText {
          background-color: transparent;
        }
      }
    }

  &__utilityNav {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    &:after {
      content: url('/images/graphic-curve-green.svg');
      display: block;
      text-align: right;
    }
  }

  &__utilityNavList {
    @extend %listReset;
    background-color: palette('green', 'light');
    display: flex;
    border-bottom-left-radius: 30px;
    li {
      border-right: 1px solid #b0da89;
      &:last-of-type {
        border-right: 0;
      }
    }
  }
    &__utilityLink {
      color: white;
      text-decoration: none;
      padding: 15px 30px;
      display: flex;
      align-items: center;


      &:hover {
        color: white;
      }
    }

  &__formInputText {
    border: 0;
    border-bottom: 2px solid palette('blue');
    width: calc(100% - 30px);

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: palette('grey','light');
      opacity: 1; /* Firefox */
      font-weight: fontWeight(bold);
      font-style: italic;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: palette('grey','light');
      font-weight: fontWeight(bold);
      font-style: italic;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: palette('grey','light');
      font-weight: fontWeight(bold);
      font-style: italic;
    }
  }

  &__formButton {
    margin-left: -5px;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid palette('blue');
    &:focus {
      outline: 3px solid palette('green', 'light');
    }
  }

  &__bottomSection {
    margin-top: 20px;
    position: relative;
  }

  &__mainNav {
    @extend %listReset;
    display: flex;
    background-color: palette('green');
    border-radius: 30px;
    justify-content: space-between;
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 12px;
    &--unevenPadding {
      padding-left: 6px;
    }
  }

  &__mainNavLink {
    color: white;
    cursor: pointer;
    font-weight: fontWeight(bold);
    padding: 14px 18px 26px 15px;
    text-decoration: none;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    display: flex;
    position: relative;
    &--thin {
      padding: 14px 13px 26px 10px;
    }
    &:hover {
      color: white;
      text-decoration: none;
      .headerNav__chevronContainer {
        //background-color: palette('blue');
      }
      .cls-1 {
        fill: #fff;
      }
    }
    &.is-active {
      color: white;
      text-decoration: none;
      background-color: palette('green', 'forest');
      .headerNav__chevronContainer {
        transform: rotate(180deg);
      }
    }

    &--alt {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: palette('green','light');
      margin-top: -6px;
      border-radius: 22px;
      padding: 11px;
      font-weight: fontWeight(bold);
      font-size: toRem(18px);
      color: #fff;
      max-width: 200px;
      line-height: 1.3rem;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__shield {
    margin-right: 10px;
  }

  &__chevronContainer {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 3px;
    position: relative;
    left: 6px;
    transition: all 0.3s;
  }

  &__chevronContainerSpecial {
    position: absolute;
    left: 165px;
    top: 34px;
  }

  &__megaMenu {
    min-height: 300px;
    background-color: palette('green', 'forest');
    position: absolute;
    top: 76px;
    width: 100%;
    border-radius: 30px;
    display: none;
    overflow: hidden;
    &.is-active {
      display: flex;
      justify-content: space-between;
    }
  }

  &__telNumber {
    color: white;
    &:hover {
      color: white;
    }
  }

  &__content {
    flex: 1 1 100%;
    color: white;
    display: flex;
    padding: 65px var(--gutter) 60px 90px;
    gap: 20px;
    a {
      color: inherit;
    }
    &:before {
      content: '';
      width: 94px;
      height: 6px;
      background-color: palette('yellow');
      top: 45px;
      position: absolute;
    }
  }
    &__contentItem {
      flex: 1 1 26%;
      &--last {
        flex: 1 1 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 4px 0;
      }
    }

  &__cta {
    width: 100%;
    max-width: 349px;
    background-color: white;
  }

  &__featuredImage {
    border-bottom-right-radius: 40px;
  }

  &__ctaContent {
    padding: 28px 20px 20px;
    h2 {
      font-size: toRem(18px);
      font-weight: fontWeight(bold);
    }
  }

  &__megaMenuList {
    @extend %listReset;
  }

  &__megaMenuLink {
    color: #fff;
    display: inline-block;
    margin: 4px 0;
    font-weight: fontWeight(bold);
    text-decoration: none;
    &:hover {
      color: white;
      text-decoration: underline;
    }
    &--subLevel {
      margin: 2px 0;
      font-weight: fontWeight(normal);
      &:last-of-type {
        margin-bottom: 10px;
      }
      &:before {
        content: '- ';
      }
    }
    /*&:hover {
      color: #fff;
      text-decoration: underline;
    }*/
  }

  &__mobileLogo {
    display: none;
    &:focus {
      outline: 3px solid palette('green', 'light');
      outline-offset: 2px;
    }
  }

  &__burger {
    background-color: transparent;
    border: 0;
    border-radius: 20px;
    display: none;
    padding: 16px 20px;
  }

  &__mobile {
    display: none;
    margin-top: 20px;
    margin-bottom: 20px;
    &.is-active {
      display: none;
    }
  }

  &__mobileNavContainer {
    max-height: calc(var(--vh, 1vh) * 100 - 160px);
    overflow-y: auto;
    padding-top: 5px;
  }

  &__mobileNavItem {
    &--alt {
      //padding-left: var(--gutter);
      //padding-right: var(--gutter);
      margin-bottom: 10px;
    }
  }

  &__mobileNav {
    @extend %listReset;
    display: flex;
    flex-direction: column;
  }
    &__mobileNavLink {
      color: #fff;
      cursor: pointer;
      font-weight: 700;
      padding: 14px 0;
      text-decoration: none;
      display: inline-flex;
      position: relative;
      margin-left: var(--gutter);
      padding-left: var(--gutter);
      padding-right: var(--gutter);
      &:hover {
        color: #fff;
        text-decoration: none;
      }
      &:focus {
        outline: 3px solid palette('green', 'light');
        outline-offset: -4px;
      }
      &.is-active {
        color: white;
        text-decoration: none;
        .headerNav__chevronContainer {
          transform: rotate(180deg);
        }
      }
      &--alt {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: palette('green','light');
        border-radius: 10px;
        padding: 8px 15px 8px 11px;
        font-weight: fontWeight(bold);
        font-size: toRem(16px);
        color: #fff;
        line-height: 1.3rem;
        .headerNav__shield {
          width: 23px;
        }
        &:hover {
          color: #fff;
          text-decoration: none;
        }
        &:focus {
          outline: 2px solid palette('green', 'forest');
          outline-offset: 3px;
        }
      }
    }

    &__mobileSubmenu {
      background-color: palette('green', 'forest');
      margin-left: var(--gutter);
      padding: 23px var(--gutter);
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
      display: none;
      &.is-active {
        display: block;
      }
    }
      &__mobileBar {
        width: 84px;
        height: 5px;
        background-color: palette('yellow');
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: var(--gutter);
        &--submenu {
          margin-left: 0;
        }
      }

  &__utilityNavMobile {
    width: 100%;
    max-width: 400px;
  }
    &__utilityNavListMobile {
      @extend %listReset;
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 1 1 50%;
      }
    }
      &__utilityLinkMobile {
        color: white;
        padding: 10px 20px 10px var(--gutter);
        display: inline-block;
        &:hover {
          color: white;
          text-decoration: underline;
        }
        &:focus {
          outline: 3px solid palette('green', 'light');
          outline-offset: -4px;
        }
      }

  &__mobileEvents {
    width: 100%;
    max-width: 400px;
    margin-bottom: 10px;
    .dateTile__deadline {
      color: white;
    }
  }

  @media (min-width: settings('mobile_width')) {
    &.is-scrolled {
      position: fixed;
      top: 0;
      margin: -120px auto 0;
      width: 100%;

      .headerNav__mainNav {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      .headerNav__container {
        //padding-top: 0;
      }

      .headerNav__bottomSection {
        //margin-top: 0;
      }

      .headerNav__topSection {
        //display: none;
      }
    }
  }

  @media (max-width: settings('mobile_width')){
    position: fixed;
    top: 0;
    width: 100%;
    &__mobileFlex {
      display: flex;
      gap: var(--gutter);
      .dateTile__item {
        margin-bottom: 20px;
      }
      &__mobileFlexItem {
        flex: 1 1 400px;
      }
    }
    &__megaMenu {
      &.is-active {
        display: none;
      }
    }
    &__mobile {
      &.is-active {
        display: block;
      }
    }
    &__megaMenu {
      display: none;
    }
    &__container {
      background-color: palette('green');
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    &__mainLogo,
    &__searchFormWrapper,
    &__utilityNav,
    &__mainNav {
      display: none;
    }
    &__topSection {
      justify-content: space-between;
      padding-left: var(--gutter);
      padding-right: var(--gutter);
    }
    &__bottomSection {
      margin-top: 0;
    }
    &__mobileLogo {
      display: inline-block;
    }
    &__burger {
      display: block;
      &:focus {
        outline: 3px solid palette('green', 'light');
      }
    }

    &__formInputText {
      color: white;
      &:focus {
        outline: 2px solid palette('blue');
      }
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }
    }
  }

  @media (max-width: 800px){
    &__mobileFlex {
      display: block;
    }
    &__mobileEvents {
      margin-top: 30px;
    }
  }
}