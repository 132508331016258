/* -------------------------------------------------------------------------- *
 * SASS Mixins *
 * -------------------------------------------------------------------------- */

@use 'functions' as Fn;
@use 'variables' as Var;

/* ------------ *
 * Text Colours *
 * ------------ *
 * Outputs classes for text colours based on the $palette_map in variables.scss
 * Naming convention is .colour (default shade) and .tintColour (other shades)
 */

@mixin colours {
  @each $colour, $shades in Var.$palette_map {
    @each $shade, $hex in $shades {
      @if ($shade != 'default') {
        .#{$shade + Fn.capitalize($colour)} {
          color: $hex;
        }
      } @else {
        .#{$colour} {
          color: $hex;
        }
      }
    }
  }
}

/* ----------- *
 * Grid Layout *
 * ----------- *
 * Outputs styles for grid layout
 * @param $columns: The default number of grid columns
 * @param $breakpoints: SASS map with breakpoint/column pairs e.g. (600px: 2, ...)
 */

@mixin grid($columns, $breakpoints) {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat($columns, 1fr);
  grid-template-rows: auto;

  @each $breakpoint, $columns in $breakpoints {
    @media (max-width: Fn.toEm($breakpoint)) {
      grid-template-columns: repeat($columns, 1fr);
    }
  }
}
