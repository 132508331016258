/* -------------------------------------------------------------------------- *
 * Main Page Header Styling*
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.pageHeader {
  // &__mainNav {
  //   margin-top: 30px;
  // }
  // &__container {
  //   @extend %container;
  // }
  &__navLevel1 {
    @extend %listReset;
    // display: flex;
    // li {
    //   a {
    //     padding: 20px;
    //   }
    // }
  }
  &__utilityList {
    @extend %listReset;
    // display: flex;
    // li {
    //   a {
    //     padding: 20px;
    //   }
    // }
  }
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: zIndex(headerNav);
  
  @media (min-width: $nav_breakpoint) {
    background-color: none;
    flex-direction: column;
    height: auto;
  }

  &__container {
    max-width: 1500px;
    margin: 0 auto;
  }

  &__logo--desktop {
    display: inline-block;
    position: absolute;

    // LW - fix this z-index
    z-index: 10000;
    left: 2.5%;
    top: 15px;

    @media (max-width: $nav_breakpoint) {
      display: none;
    }
  }

  &__logo--mobile {
    height: 70px;
    position: relative;
    z-index: 5;
    margin-top: 10px;

    @media (min-width: $nav_breakpoint) {
      display: none;
    }

  }
  
  &__logo--white {
    svg g path{
      fill: #fff;
    }
  }

  &--mobile {

    @media (max-width: $nav_breakpoint) {
      justify-content: flex-end;
      background-color: palette('green');
      min-height: 100vh;
      height: auto;
      position: absolute;
      width: 100%;
      // margin-left: -40px;
      padding-top: 70px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      z-index: 4;
      // margin-top: 70px;
    }
  }

  &__utilityNav {

    @media (max-width: $nav_breakpoint) {
      width: 320px;
    }
  }

  &__utilityList {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: palette('green');
    width: 320px;
    
    a {
      color: white;
      text-decoration: none;
      width: 100%;
      display: inline-block;
      height: calc(100% + 7px);
      margin-top: -7px;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    @media (min-width: $nav_breakpoint) {
      width: auto;
      height: 75px;
      flex-direction: row;
      background-color: palette('green', 'light');
      border-radius: 0 0 0 20px;
      
      // not totally sold on this LW
      // width: 50%;
      margin-left: 60%;
    }

    &-flex-container {
      display: flex;
      justify-content: space-around;
      line-height: 2.5;

      li {
        width: 50%;
        
        @media (min-width: $nav_breakpoint) {
          border-right: 1px solid white;
          padding-top: 7px;
          text-align: center;
          width: inherit;
          font-weight: bold;

          &:hover {
            background-color: palette('green');
          }
        }
      }
      
      @media (min-width: $nav_breakpoint) {
        width: 50%;
        line-height: 1.8;
      }
    }

  }

  &__flyout-nav {
    background-color: palette('green');
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: toRem(18px);
    margin: 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 1;
    width: 320px;
    
    &-li {
      display: flex;
      position: relative;

      @media (max-width: $nav_breakpoint) {
        line-height: 2.5;
      }
      
      p {
        margin: 0;
      }
      
      .shield {
        display: none;
      }

      &:hover {

        svg.chevron {
          transform: rotate(180deg);
          transition-duration: .3s;
        }

        svg.chevron circle {
          fill: palette('blue');
        }

        svg.chevron g path {
          fill: white;
        }
      }
      
      &:first-of-type {
        max-width: 270px;
        border-radius: 20px;
        background-color: palette('green', 'light');
        line-height: 1.3;
        padding: 2px 5px 2px 10px;;
        margin-left: -10px;
        
        @media (min-width: $nav_breakpoint) {
          max-width: 190px;
          padding: 10px;
          margin-left: 0;
        }
        
        a {
          
          @media (max-width: $nav_breakpoint) {
            max-width: 255px;
            flex-direction: row-reverse;
          }
        }
        
        svg.chevron {
          display: none;
        }
        
        .shield {
          display: inline-block;
          margin-right: 10px;
          width: 31px;
        }
      }
    }
    
    svg {
      margin-left: 10px;
      
      @media (min-width: $nav_breakpoint) {
        margin-left: 5px;
        max-width: 15px;
        min-width: 15px;
      }
    }

    a {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
      
      &:hover {
        text-decoration: underline;
      }

      // tab background for selected link - does not look correct on Find The Right Coverage
      // &.is-active {
      //     @media (min-width: $nav_breakpoint) {
      //       &::after {
      //         content: '';
      //         position: absolute;
      //         top: -16px;
      //         width: 120%;
      //         background: palette('green', 'forest');
      //         left: -10%;
      //         height: 80px;
      //         border-radius: 20px 20px 0 0;
      //         z-index: -1;
      //       }
      //     }
      //   }
    }
    
    @media (min-width: $nav_breakpoint) {
      margin-top: -15px;
      padding: 8px 20px 8px 5px;
      flex-direction: row;
      width: 95%;
      max-width: 1366px;
      border-radius: 27px;
      align-items: center;
    }
  }
  
  &__burger {
    display: inline-block;
    background: palette('green');
    border: palette('green');
    padding: 10px 24px 2px 24px;
    margin: 0;
    border-radius: 22px;
    z-index: 4;
    margin-top: 10px;
    
    @media (min-width: $nav_breakpoint) {
      display: none;
    }
  }

  &__burger-container {
    display: flex;
    justify-content: flex-end;
    max-height: 60px;
  }

  &__news {
    padding-left: 0;
  }

  &__news-deadline {
    color: white;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &__news-item {
    display: flex;
    align-items: inherit;
    
    p {
      margin: 0;
    }
  }
  
  &__news-date {
    color: white;
    background-color: palette('blue');
    border-radius: 12px 0 0 12px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0 15px;
    line-height: 1.1;
    margin: 0;
    width: 70px;
  }

  &__news-title {
    color: palette('blue');
    background-color: white;
    border-radius: 0 12px 12px 0;
    padding: 15px;
    font-weight: bold;
    line-height: 1.3;
    margin: 0;
  }
}

ul.pageHeader--desktop-hidden {

  @media (min-width: $nav_breakpoint) {
    display: none;
  }
}

.pageHeader--mobile-hidden {

  @media (max-width: $nav_breakpoint) {
    display: none;
  }
}


.border-div {

  height: 60px;
  border-radius: 0 30px 0 0;
  position: relative;
  z-index: 1;
  margin-top: -29px;
  background: white;

  @media (max-width: $nav_breakpoint) {
    display: none;
  }
}

// LW - double check not in use
.mainNavFlyout {
  // border: 1px solid blue;
  display: none;

  & .is-active {
    display: flex;
    border: 1px solid salmon;

  }
}

.mainNavFlyout.is-active {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  background: palette('green', 'forest');
  width: 100%;
  border-radius: 20px;
  
  @media (min-width: $nav_breakpoint) {
    position: absolute;
    top: 80px;

  }

}

.pageHeader__flyout-nav-content {
  // border: 1px solid cyan;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .pageHeader__news {
    width: 300px;
  }
  
  @media (min-width: $nav_breakpoint) {
      height: 350px;
    }
  }

  .pageHeader__flyout-news-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;

    p:not(.pageHeader__news-item p) {
      color: #fff;
      margin-bottom: 30px;
    }
  }

  .pageHeader__learn-more-card {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0 20px 20px 0;
    justify-content: space-between;
    
    @media (min-width: $nav_breakpoint) {
      height: 350px;
    }
    
    img {
      border-radius: 0 20px 20px 0;
    }
    
    p {
      width: 80%;
      text-align: left;
      margin-left: 10px;
      line-height: 1.4;
    }
  }
  
  .pageHeader__learn-more-button {
    width: 200px;
    border-radius: 0 20px 20px 20px;
    background-color: palette('green', 'light');
    border: transparent;
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 15px;
    padding: 8px 0;
  }

  .pageHeader__flyout-extras-container {
    display: none;
    
    @media (min-width: $nav_breakpoint) {
      display: flex;
      justify-content: space-between;
    }
  }

  .pageHeader__flyout-links-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 40%;
    padding: 30px 0 20px 20px;
    
    @media (min-width: $nav_breakpoint) {
      height: 350px;
      
    }
  }


.pageHeader__flyout-sublinks {
  display: flex;

  &::before {
  content: '-';
  color: #fff;
  
  
  }
}
