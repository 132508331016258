/* -------------------------------------------------------------------------- *
 * Cookie Consent Popup *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.consent {
  background-color: palette('black');
  border: 0;
  bottom: 0;
  color: white;
  left: 0;
  padding: var(--gutter) 0;
  position: relative;
  width: 100%;
  z-index: 10;

  &__container {
    display: flex;

    @extend %container;

    @media (max-width: toEm(800px)) {
      display: block;
    }
  }

    &__content {
      flex-grow: 1;
      margin-right: var(--gutter);

      a {
        color: inherit;
      }

      @media (max-width: toEm(800px)) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    &__actions {
      align-items: flex-end;
      display: flex;

      @media (max-width: toEm(800px)) {
        justify-content: flex-end;
      }
    }
}
