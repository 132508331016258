/* -------------------------------------------------------------------------- *
 * Main container styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.main {
  background-color: palette('black', 'lighter');
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  margin-top: -24px;
  padding-top: 40px;
  overflow: hidden;

  &.is-scrolled {
    padding-top: 240px;
  }

  @media (max-width: settings('mobile_width')){
    padding-top: 130px;

    &.is-scrolled {
      padding-top: 130px;
    }
  }
}