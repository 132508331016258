/* -------------------------------------------------------------------------- *
 * Block styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.block {
  @extend %block;
  @extend %block--greySwirls;

  &--negativeMarginTop {
    margin-top: -50px;
    padding-top: 67px;
  }

  &__container {
    @extend %container;
  }

  header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px){
      flex-direction: column;
    }

    h2 {
      @extend %defaultHeading;
      color: white;
      &:after {
        content: "";
        display: block;
        width: 90px;
        height: 6px;
        margin-top: 20px;
        background-color: palette('yellow');
      }
    }
  }

  @media (max-width: 1200px){
    &--negativeMarginTop {
      margin-top: 0;
    }
  }
}