/* -------------------------------------------------------------------------- *
 * Calendar styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.calendar {
  &--mobile {
    display: none;
  }

  &__eventContent {
    margin-bottom: 40px;
  }

  &__eventDate {
    margin: 30px 0;
    h2 {
      @extend %defaultlabel;
      color: palette('green', 'light');
    }
  }
    &__date {
      @extend %defaultHeading;
    }

  &__eventDetails {
    h2 {
      @extend %defaultlabel;
      color: palette('green', 'light');
    }
  }

  @media (max-width: 930px){
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }
}