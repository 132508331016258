/* -------------------------------------------------------------------------- *
 * Contact Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.contact {
  &__header {
    @extend %block;
  }

  &__container {
    @extend %container;
  }

  &__headerFlex {
    display: flex;
    header {
      h1 {
        margin-bottom: 1rem;
      }
    }


  }



  &__content {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
    flex: 1 1 50%;
  }
    &__contentContainer {
      width: calc(1320px/2);
      padding: 0 var(--gutter) 20px var(--gutter);
    }

  &__locationCard {
    padding: 22px;
    border-radius: 10px;
    background-color: white;
    width: 100%;
    max-width: 630px;
  }
    &__locationIcon {
      margin-right: 20px;
    }
    &__locationHeader {
      font-size: toRem(20px);
      font-weight: fontWeight(medium);
    }
    &__locationFlex {
      display: flex;
      gap: 40px;
    }
    &__locationFlexItem {
      flex: 1 1 60%;
      &--last {
        flex: 1 1 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

  &__locationLink {
    color: palette('green');
    &:hover {
      color: palette('green');
    }
  }

  &__googeMap {
    flex: 1 1 50%;
    min-height: 400px;
  }

  &__contentContainer {
    @extend %block;
    @extend %block--bottomPaddingOnly;
  }

  &__btnWrapper {
    margin-top: 20px;
  }

  &__tollFreeGrid {
    @extend %grid;
    @extend %grid--small;
    margin-bottom: 40px;
  }

  &__tollFreeItem {
    h2 {
      font-size: toRem(20px);
      font-weight: fontWeight(medium);
      margin-bottom: 10px;
    }
    a {
      color: palette('green');
      &:hover {
        color: palette('green');
      }
    }
  }

  &__tollFreeNumbers {
    margin: 0.5rem 0;
    span {
      &:after {
        content: ' | ';
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }

  &__locations {
    header {
      h2 {
        @extend %defaultHeading;
        margin-bottom: 0.5rem;
      }
      margin-bottom: 40px;
    }
  }

  &__locationsGrid {
    @extend %grid;
    margin: 40px 0;
  }

  &__locationAddress,
  &__locationNumbers {
    margin: 10px 0;
  }

  @media (max-width: 1250px){
    &__headerFlex {
      display: block;
    }
    &__content {
      width: 100%;
    }
    &__content {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1320px;
      padding-left: 0;
      padding-right: 0;
      justify-content: flex-start;
    }
    &__contentContainer {
      width: 100%;
      max-width: 630px;
    }
  }
}