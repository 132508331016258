/* -------------------------------------------------------------------------- *
 * Buttons *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.btn,
a.btn {
  background-color: palette('green', 'light');
  border-radius: 17px;
  border: none;
  color: white;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 9px 17px;
  text-decoration: none;
  font-weight: fontWeight(bold);
  font-size: toEm(16px);
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }

  &--primary {
    border-top-left-radius: 0;
    -webkit-box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    transition: all 0.3s ease-out;
    &:hover {
      color: white;
      background-color: palette('green');
    }
    &:focus {
      outline: 2px solid palette('green', 'forest');
      outline-offset: -2px;
    }
  }

  &--secondary {
    border-bottom-left-radius: 0;
    -webkit-box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    transition: all 0.3s ease-out;
    &:hover {
      color: white;
      background-color: palette('green');
    }
    &:focus {
      outline: 2px solid palette('green', 'forest');
      outline-offset: -2px;
    }
  }

  &--tertiary {
    background-color: palette('blue');
    border-radius: 17px;
    border-bottom-left-radius: 0;
    padding: 5px 15px;
    font-size: toEm(12px);
    -webkit-box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    &:hover {
      color: white;
    }
    span {
      text-decoration: underline;
    }
    .btn__icon {
      top: -1px;
    }
  }

  &--primaryWhite {
    color: palette('green');
    background-color: white;
    border-top-left-radius: 0;
    -webkit-box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    transition: all 0.3s ease-out;
    margin-right: 0;
    &:hover {
      color: palette('green', 'light');
    }
    &:focus {
      outline: 2px solid palette('green', 'forest');
      outline-offset: -2px;
    }
  }

  &--filter {
    background-color: white;
    color: palette('green', 'light');
    padding: 7px 17px;
    &.is-active {
      background-color: palette('green', 'light');
      color: white;
    }
  }

  &__icon {
    margin-right: 9px;
    position: relative;
    top: 3px;
    &--filter {
      top: -1px;
      margin-right: 0;
      margin-left: 9px;
    }
  }

  &--textLink {
    font-size: toRem(16px);
    font-weight: fontWeight(bold);
    background-color: transparent;
    border-radius: 0;
    color: palette('green', 'light');
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 12px;
    padding: 0 12px 0 0;
    text-decoration: none;
    display: inline-block;
    line-height: 20px;
    &:after {
      content: url('/images/icon-arrow-green.svg');
      background: none;
      display: inline;
      width: 15px;
      height: 11px;
      top: 1px;
      right: -5px;
      left: unset;
      transition: right 0.3s;
      position:relative;
    }
    &:hover {
      color: palette('green', 'light');
      text-decoration: underline;
    }
  }

  &__toggle {
    color: palette('black', 'dark');
    padding: 12px 20px;
    background-color: white;
    font-weight: fontWeight(bold);
    border: none;
    margin-right: 2px;
    display: flex;
    align-items: center;
    &.is-active {
      color: white;
      background-color: palette('blue');
      .btn__toggleCircle {
        fill: #069748;
      }
    }

    &--leftEnd {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      margin-right: 2px;
    }
    &--rightEnd {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }

  &__toggleIcon {
    margin-right: 8px;
  }

  &__toggleCircle {
    fill: white;
  }
}
