
@use 'baseline' as *;
@use 'placeholders' as *;

.legend {
  margin: 1em 0;
  width: 100%;
  max-width: 500px;

  &__item {
    display: flex;
    align-items: flex-start;
    margin: 0.5em 0;
    gap: 10px;
    font-size: 0.9rem;
  }

  &__icon {
    position: relative;
    top: 1px;
  }
}