
@use 'baseline' as *;
@use 'placeholders' as *;

.producers {
  &__operationsLabel {
    color: white;
    font-size: toRem(20px);
    font-weight: bold;
  }

  &__typeGrid {
    margin: 24px 0;
    display: none;
    &.is-active {
      @extend %grid;
      @extend %grid__producers;
    }
    &--small {
      &.is-active {
        @extend %grid;
        @extend %grid__producers--small;
      }
    }

  }
    &__typeCell {
      color: palette('black', 'dark');
      flex: 1 0 25%;
    }
      &__wrapper {
        border-radius: 20px;
        overflow: hidden;
      }
      &__container {
        background-color: white;
        padding: 20px;
        &--head {
          padding: 20px 10px;
          font-weight: fontWeight(bold);
        }
        ul {
          @extend %listReset;
          margin-top: 10px;
          li {
            padding-left: 5px;
            &::marker {
              content: '•';
              font-weight: fontWeight(bold);
              color: palette('green', 'light');
            }
          }
        }
        >ul {
          >li {
            margin: 10px;
          }
        }
      }

  &__head {
    text-align: center;
    min-height: 290px;
    background-color: white;
    h2 {
      font-size: toRem(28px);
      line-height: 1.1em;
    }
  }

  &__iconGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__orangeHeader {
    color: white;
    background-color: palette('orange');
    text-transform: uppercase;
    text-align: center;
    padding: 14px var(--gutter);
    font-size: toRem(27px);
    font-weight: fontWeight(bold);
    line-height: 1.1em;
  }

  &__sectionHeader {
    color: white;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: toRem(22px);
    text-align: center;
    &--blue {
      background-color: palette('blue');
    }
    &--green {
      background-color: palette('green', 'light');
    }
  }

  &__footer {
    padding: 18px 20px 24px;
    background-color: palette('green');
    text-align: center;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
    &__footerLink {
      color: white;
      text-decoration: underline;
      &:hover {
        color: white;
      }
      &:focus {
        outline: 2px solid palette('green', 'light');
        outline-offset: 3px;
      }
    }

  &__filter {
    display: flex;
    margin: 17px 0 40px;
  }

  @media (max-width: 980px){
    &__typeGrid {
      &.is-active {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 600px){
    &__typeGrid {
      &.is-active {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &__head {
      min-height: auto;
    }
    &__container--head {
      padding-bottom: 30px;
    }
    &__typeCell {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 580px){
    &__filter {
      flex-direction: column;
      .btn__toggle {
        border-radius: 30px;
        margin: 10px 0;
      }
    }
  }
}