@use 'baseline' as *;
@use 'placeholders' as *;

.footer {
    &__top {
        background-color: palette('green');
        color: white;
        padding: 30px 0;

        a {
            color: white;
            text-decoration: underline;
            &:hover {
                color: white;
            }
        }

        li {
            margin-right: 30px;
        }

        p {
            margin: 0;
        }

        .flex-row {
            align-items: flex-start;
        }
    }
    &__flex {
        display: flex;
        justify-content: space-between;
    }

    &__bottom {
        padding: 30px 0;
    }

    &__bottomFlex {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &__legal {
        padding-right: 30px;
    }

    &__orgName {
        font-weight: fontWeight(bold);
        text-transform: uppercase;
    }
    &__phone {
        span {
            font-weight: fontWeight(bold);
        }
    }

    &__container {
        @extend %container;
    }

    &__list {
        @extend %listReset;
        display: flex;
        justify-content: space-between;
    }

    &__menu {
        @extend %listReset;
        display: flex;
        margin-bottom: 2em;
        li {
            a {
                font-weight: fontWeight(bold);
            }
        }
    }

    &__socialLinks {
        @extend %listReset;
        li {
            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__flex {
        display: flex;
    }

    &__link {
        margin-right: 30px;
        font-weight: fontWeight(bold);
        &:last-of-type {
            margin-right: 60px;
        }
    }

    &__logoGarden {
        @extend %listReset;
        display: flex;
        li {
            margin-right: 24px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 1130px){
        &__flex {
            flex-direction: column;
        }
        &__list {
            justify-content: flex-start;
            margin-bottom: 24px;
        }
        &__link {
            margin-bottom: 24px;
        }
        &__bottomFlex {
            flex-direction: column;
        }
        &__legal {
            display: block;
            order: 2;
        }
        &__logoGarden {
            order: 1;
            margin-bottom: 40px;
        }
        &__featuredLogo {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 600px){
        &__list {
            display: block;
            margin-bottom: 0;
            li {
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
    }

    @media (max-width: 450px){
        &__bottomFlex {
            align-items: flex-start;
        }
    }
}