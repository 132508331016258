/* -------------------------------------------------------------------------- *
 * Dynamic Tables *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

ul.tabledynamicquicklinks {
  font-weight: bold;
  @extend %listReset;
  margin: 1em 0 2em;
}

  table.tabledynamic {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 40px;
  }

  table.tabledynamic caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  table.tabledynamic tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }

  table.tabledynamic th,
  table.tabledynamic td {
    padding: .625em;
  }

  table.tabledynamic th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: left;
  }

  table.tabledynamic thead {
    position: sticky;
    top: 0;
  }

@media screen and (max-width: 1024px) {
    table.tabledynamic {
      border: 0;
    }

    table.tabledynamic caption {
      font-size: 1.3em;
    }

    table.tabledynamic thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table.tabledynamic tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }

    table.tabledynamic td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }

    table.tabledynamic td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table.tabledynamic td:last-child {
      border-bottom: 0;
    }
}
