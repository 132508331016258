/* -------------------------------------------------------------------------- *
 * Featured Pages Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.featuredPages {
  @extend %block;
  @extend %block--grey;

  &__container {
    @extend %container;
  }

  &__grid {
    @extend %grid;
    @extend %grid--small;
  }

  &__cell {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__content {
    width: 100%;
    max-width: 256px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &:after {
      content: url('/images/graphic-curve-white.svg');
      display: block;
    }
  }

  &__contentContainer {
    padding: 30px;
    background-color: white;
    border-bottom-left-radius: 50px;
    p {
      font-size: toEm(20px);
      font-weight: fontWeight(bold);
      line-height: 25px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &__label {
    font-size: toEm(16px);
    color: palette('green', 'light');
    font-weight: fontWeight(bold);
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 11px;
  }

  @media (max-width: 50em){
    &__cell {
      background-size: 100%;
      padding-bottom: 250px;
      background-position: bottom;
    }
    &__content {
      max-width: none;
    }
    &__contentContainer {
      border-radius: 0;
      width: 100%;
    }
  }

  @media (max-width: 700px){
    &__cell {
      padding-bottom: 210px;
    }
  }
  @media (max-width: 560px){
    &__cell {
      padding-bottom: 170px;
    }
  }
  @media (max-width: 450px){
    &__cell {
      padding-bottom: 140px;
    }
  }
  @media (max-width: 400px){
    &__cell {
      padding-bottom: 80px;
    }
  }
}