
@use 'baseline' as *;
@use 'placeholders' as *;

.generic {
  &__header {
    margin-bottom: 40px;
  }

  &__container {
    @extend %fullWidthContainer;
  }

  &__content {
    @extend %container--genericContent;
  }

  &__contentContainer {
    @extend %block;
    @extend %block--bottomPaddingOnly;
  }
}