/* -------------------------------------------------------------------------- *
 * News Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.news {
  &__header {
    margin-bottom: 40px;
  }

  &__flex {
    display: flex;
  }
    &__backContainer {
      flex: 0 0 130px;
    }
    &__entryContent {
      flex: 1 1 100%;
    }

  &__container {
    @extend %fullWidthContainer;
  }

  &__content {
    @extend %container--genericContent;
  }

  &__PostedOn {
    text-transform: uppercase;
    margin-top: 15px;
    span {
      font-weight: fontWeight(bold);
    }
  }

  &__contentContainer {
    @extend %block;
    @extend %block--bottomPaddingOnly;
  }

  &__featuredItem,
  &__gridItem {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
  }
  &__featuredItem {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  &__cardInfo {
    display: flex;
    justify-content: space-between;
    @media (max-width: 430px){
      display: block;
      .news__postDate {
        text-align: left;
        margin-top: 10px;
      }
    }
  }
    &__tag {
      font-size: toRem(16px);
      font-weight: fontWeight(bold);
      text-transform: uppercase;
      padding-right: 20px;
    }
    &__postDate {
      font-size: toRem(16px);
      font-weight: fontWeight(medium);
      color: palette('blue');
      text-transform: uppercase;
      width: 35%;
      text-align: right;
    }
    &__itemFlex {
      display: flex;
      @media (max-width: 475px){
        display: block;
        .news__itemButton {
          justify-content: flex-start;
        }
        .news__itemContent {
          width: 100%;
        }
      }
    }
    &__itemContent {
      width: 70%;
    }
    &__itemButton {
      width: 30%;
      min-width: 130px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    &__cardTitle {
      font-size: toRem(20px);
      font-weight: fontWeight(bold);
      color: palette('green');
      line-height: 1.2em;
      margin: 20px 0;
      &--large {
        font-size: toRem(36px);
        line-height: 1.2em;
        margin: 20px 0;
      }
    }
  &__grid {
    @extend %grid;
    @extend %grid--small;
    grid-gap: 20px;
    margin-bottom: 50px;
  }

  @media (max-width: 800px){
    &__flex {
      display: block;
    }
    &__backContainer {
      margin-bottom: 20px;
    }
    &__entryContent {
    }
  }
}