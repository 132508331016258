.burger {
  display: block;
  width: 22px;
  height: 19px;
  margin-left: 10px;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  &:first-child {
    margin-left: 0;
  }

  .is-active > & {
    transform: rotateY(180deg);
  }

  &__front,
  &__back {
    backface-visibility: hidden;
    width: 22px;
    height: 19px;
    left: 0;
    position: absolute;
    top: 0;
  }

  &__front {
    transform: rotateY(0);
  }

  &__back {
    transform: rotateY(180deg);
  }
}
