/* -------------------------------------------------------------------------- *
 * Filter styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.filter {
  margin: 2em 0 0;
  &__taxList {
    @extend %listReset;
    display: flex;
    flex-wrap: wrap;
  }
  &__taxItem {
    a.btn {
      margin-right: 20px;
    }
  }
  &__noResults {
    margin: 50px 0;
    padding-left: 20px;
    font-weight: fontWeight(bold);
  }
}