/* -------------------------------------------------------------------------- *
 * Header Notification Bar *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.notification {
  color: white;
  display: none;
  margin-top: calc(var(--notificationHeight) * -1);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative;
  width: 100%;
  z-index: 5;
  bottom: 0;
  background-color: palette('green', 'forest');
  -webkit-box-shadow: 0px 6px 13px 6px rgba(0,0,0,0.55);
  box-shadow: 0px 6px 13px 6px rgba(0,0,0,0.55);

  &.is-active {
    display: block;
  }

  &__container {
    align-items: center;
    display: flex;
    padding: 20px;
    @extend %container;
  }

    &__content {
      flex-grow: 1;
      margin-right: var(--gutter);
      text-align: center;
      line-height: 1.6em;
      a {
        color: inherit;
      }
    }

  .btn {
    margin: 0;
    padding: 6px 15px;
  }
}
