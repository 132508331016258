/* -------------------------------------------------------------------------- *
 * Accordion Content Styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.accordion {
  border-top: 1px solid palette('black');
  margin: 2rem 0;

  &__item {
    border-bottom: 1px solid palette('black');
  }

    &__header {
      padding: 20px 40px 20px 0;
      position: relative;
      @extend %smallHeading;

      &:after {
        background: url('/images/icon-chevron-down.svg') center / cover no-repeat;
        content: '';
        height: 35px;
        position: absolute;
        right: 0;
        top: 15px;
        transition: transform 0.4s;
        width: 35px;
      }

      &.is-active:after {
        transform: rotate(180deg);
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__content {
      display: none;
      padding-bottom: 20px;

      &.is-active {
        display: block;
      }
    }
}
