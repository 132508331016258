/* -------------------------------------------------------------------------- *
 * Tobii styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

/* Link */
.tobii-zoom {
  border: 0;
  box-shadow: none;
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.tobii-zoom img {
  display: block;
}

.tobii-zoom__icon {
  background-color: rgba(26, 42, 58, 0.94);
  bottom: 0;
  color: #fff;
  line-height: 1;
  position: absolute;
  right: 0;
}

.tobii-zoom__icon svg {
  color: #fff;
  fill: none;
  height: 1.11111em;
  padding-bottom: 0.22222em;
  padding-left: 0.22222em;
  padding-right: 0.22222em;
  padding-top: 0.22222em;
  pointer-events: none;
  stroke-linecap: square;
  stroke-linejoin: miter;
  stroke-width: 2;
  stroke: #fff;
  width: 1.11111em;
}

/* Hide scrollbar if lightbox is displayed */
.tobii-is-open {
  overflow-y: hidden;
}

/* Lightbox */
.tobii {
  background-color: rgba(palette('teal', 'dark'), 0.9);
  bottom: 0;
  box-sizing: border-box;
  contain: strict;
  font-size: 18px;
  left: 0;
  line-height: 1.5555555555555556;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1337;
}

.tobii[aria-hidden="true"] {
  display: none;
}

.tobii *,
.tobii *::before,
.tobii *::after {
  box-sizing: inherit;
}

/* Slider */
.tobii__slider {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: transform;
}

.tobii__slider--animate:not(.tobii__slider--is-dragging) {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (prefers-reduced-motion: reduce) {
  .tobii__slider--animate:not(.tobii__slider--is-dragging) {
    transition: none;
  }
}

.tobii__slider--is-draggable .tobii__slider__slide__content {
  cursor: -webkit-grab;
  cursor: grab;
}

.tobii__slider--is-dragging .tobii__slider__slide__content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* Slide */
.tobii__slider-slide {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/* Slide content */
.tobii [data-type] {
  max-height: 85vh;
  max-width: 85vw;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.tobii [data-type=youtube] {
  height: 45vw;
  max-height: 80vh;
  max-width: 142.222vh;
  position: relative;
  width: 80vw;
}

.tobii [data-type=youtube] iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.tobii [data-type] iframe,
.tobii [data-type] video {
  display: block;
}

.tobii [data-type] > figure {
  margin: 0;
  position: relative;
}

.tobii [data-type] > figure > img {
  display: block;
  height: auto;
  max-height: 85vh;
  max-width: 85vw;
  width: auto;
}

.tobii [data-type] > figure > figcaption {
  background-color: rgba(255, 255, 255, 0.94);
  bottom: 0;
  color: #1a2a3a;
  padding-bottom: 0.22222em;
  padding-left: 0.44444em;
  padding-right: 0.44444em;
  padding-top: 0.22222em;
  position: absolute;
  white-space: pre-wrap;
  width: 100%;
}

.tobii [data-type="html"] video {
  cursor: auto;
  max-height: 85vh;
  max-width: 85vw;
}

.tobii [data-type="iframe"] {
  /* Fix iframe scrolling on iOS */
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
}

.tobii [data-type="iframe"] iframe {
  height: 85vh;
  width: 85vw;
}

/* Buttons */
.tobii > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0.05556em solid transparent;
  color: #fff;
  cursor: pointer;
  font: inherit;
  line-height: 1;
  margin: 0;
  opacity: 0.5;
  padding-bottom: 0.22222em;
  padding-left: 0.22222em;
  padding-right: 0.22222em;
  padding-top: 0.22222em;
  position: absolute;
  touch-action: manipulation;
  transition-duration: 0.3s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  will-change: opacity, transform;
  z-index: 1;
}

@media screen and (prefers-reduced-motion: reduce) {
  .tobii > button {
    transition: none;
    will-change: opacity;
  }
}

.tobii > button svg {
  pointer-events: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-linecap: square;
  stroke-linejoin: miter;
  fill: none;
  color: #fff;
}

.tobii > button:active, .tobii > button:focus, .tobii > button:hover {
  opacity: 1;
  transform: scale(0.84);
}

@media screen and (prefers-reduced-motion: reduce) {
  .tobii > button:active, .tobii > button:focus, .tobii > button:hover {
    transform: none;
  }
}

.tobii > button.tobii__prev, .tobii > button.tobii__next {
  top: 50%;
  top: calc(50% - 2.22222em);
}

.tobii > button.tobii__prev svg, .tobii > button.tobii__next svg {
  height: 3.88889em;
  width: 3.88889em;
}

.tobii > button.tobii__prev {
  left: 0;
}

.tobii > button.tobii__next {
  right: 0;
}

.tobii > button.tobii__close {
  right: 0.27778em;
  top: 1em;
}

.tobii > button.tobii__close svg {
  height: 3.33333em;
  width: 3.33333em;
}

.tobii > button:disabled, .tobii > button[aria-hidden="true"] {
  display: none;
}

/* Counter */
.tobii__counter {
  background-color: transparent;
  color: #fff;
  font-size: 1.11111em;
  left: 1em;
  line-height: 1;
  position: absolute;
  top: 2.22222em;
  z-index: 1;
}

.tobii__counter[aria-hidden="true"] {
  display: none;
}

/* Loader */
.tobii-loader {
  display: inline-block;
  height: 5.55556em;
  left: calc(50% - 2.77778em);
  position: absolute;
  top: calc(50% - 2.77778em);
  width: 5.55556em;
}

.tobii-loader::before {
  animation: spin 1s infinite;
  border-radius: 100%;
  border: 0.22222em solid #949ba3;
  border-top-color: #fff;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}