/* -------------------------------------------------------------------------- *
 * Accessible Skip Links *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.skip {
  background: white;
  display: flex;
  left: 50%;
  padding: 5px 10px;
  position: absolute;
  transform: translate(-50%, -100%);


  &:focus-within {
    transform: translate(-50%, 0);
  }

  &__list {
    display: flex;

    @extend %listReset;
  }

    &__item {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

      &__link {

      }
}
