/* -------------------------------------------------------------------------- *
 * Section Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.section {
  &__container {
    @extend %container;
    display: flex;
    gap: 70px;
    margin-top: 20px;
    margin-bottom: 40px;

    @media (max-width: 1050px){
      display: block;
    }
  }
    &__aside {
      flex: 0 0 290px;
      margin-bottom: 35px;
      &TopLevel {
        @extend %listReset;
        > ul {
          @extend %listReset;
        }
      }
    }

    &__navList {
      @extend %listReset;
      > li > ul {
        @extend %listReset;
      }
      > li > a {
        font-weight: fontWeight(bold);
      }
    }

  &__item {
    margin: 2px 0;
    &--is-active {
      background-color: white;
      padding: 2px 10px;
      margin: 0 0 0 -10px;
      > ul {
        font-weight: fontWeight(bold);
        padding-left: 15px;
        a {
          color: palette('green', 'light');
        }
        a.section__link--is-active {
          color: palette('grey', 'light');
        }
      }
      .section__item--is-active {
        padding: 0;
        margin: 0;
        ul {
          li {
            a {
              font-weight: fontWeight(normal);
            }
          }
        }
        .section__item--is-active {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

    &__is-dropdown {
      display: none;
      &--is-active {
        display: block;
      }
      &:first-child {
        display: block;
      }
    }

    &__content {
      flex-basis: 100%;
      position: relative;
      padding-bottom: 50px;
      z-index: 0;
    }

    &__contentIndent {
      padding-left: 65px;
      @media (max-width: 1120px){
        padding-left: 0;
      }
    }

  &__landingHeader {
    background-position: top right;
    border-radius: 20px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px;

    h1 {
      @extend %largeHeading;
    }

    img {
      height: auto;
      left: 50%;
      max-width: none;
      min-width: 101%;
      min-height: 101%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: auto;
      z-index: 2;
    }

    @media (max-width: 1280px){
      margin-bottom: 40px;
    }
  }
    &__landingIntro {
      background-color: white;
      border-bottom-right-radius: 50px;
      padding-left: var(--gutter);
      padding-right: 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 80%;
      max-width: 474px;
      position: relative;
      z-index: 3;
      header {
        margin-bottom: 1em;
      }
    }
    &__deadlinesContainer {
      @media (max-width: 1280px){
        padding-left: 65px;
      }
      @media (max-width: 1000px){
        padding-left: 0;
      }
    }
    &__landingDeadlines {
      background-color: palette('green', 'light');
      position: absolute;
      z-index: 3;
      width: 290px;
      right: -20px;
      top: 180px;
      border-radius: 10px;
      padding: 7px 10px;
      color: white;
      @media (max-width: 1280px){
        position: relative;
        top: 0;
        margin-top: 40px;
        margin-bottom: 40px;
        right: 0;
      }
    }
    &__dateIcon {
      position: relative;
      margin-right: 10px;
      top: -4px;
    }
    &__deadLineLabel {
      font-size: toEm(18px);
      font-weight: fontWeight(bold);
    }
    &__dateTile {
      background-color: white;
      padding: 10px;
      color: palette('black');
      font-weight: fontWeight(bold);
      border-radius: 5px;
      display: flex;
      line-height: 20px;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        .section__dateTitle {
          color: palette('black');
          text-decoration: underline;
        }
      }
    }
    &__date {
      background-color: palette('green', 'light');
      border-radius: 3px;
      margin-right: 10px;
      text-align: center;
      color: white;
      line-height: 1.7em;
      height: 71px;
      width: 62px;
      div {
        color: palette('black');
        font-size: toEm(30px);
        line-height: 1em;
        padding: 7px 13px;
        background-color: palette('black', 'lighter');
        border-top-left-radius: 20px;
        letter-spacing: 3px;
        text-align: center;
      }
    }
    &__dateAllLink {
      text-align: center;
      margin-top: 5px;
      a {
        text-decoration: none;
        font-weight: fontWeight(bold);
        color: white;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
    }

  @media (max-width: 640px){
    &__landingIntro {
      width: 100%;
      max-width: unset;
      border-bottom-right-radius: 0;
    }
    &__landingHeader {
      margin-bottom: 40px;
      padding-bottom: 0;
      img {
        position: relative;
        transform: none;
        left: 0;
        top: 0;
        min-width: unset;
        min-height: unset;
        max-width: 100%;
      }
    }
  }
}

.section {
  &__aside nav ul li a {
    font-weight: bold;
  }

  &__aside nav ul li ul li a {
    // border: 1px solid blue;
    font-weight: normal;
  }

  &__aside nav ul li ul li ul li a {
    // border: 1px solid darkgreen;
    color: palette('green', 'light');
    font-weight: normal;
  }
}