/* -------------------------------------------------------------------------- *
 * LinkCard Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.linkCard {
  @extend %grid;
  @extend %grid--large;
  color: #fff;
  margin-top: 50px;
  margin-bottom: 50px;

  &__item {
    border-radius: 15px;
    background-color: palette('blue');
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > * {
      flex-basis: 100%;
    }
  }
  &__icon {
    border-bottom-left-radius: 15px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;
    img {
      width: 100px;
    }
  }
  &__text {
    padding: 8px;
    text-align: center;
    font-weight: fontWeight(bold);
    font-size: toEm(18px);
    line-height: 21px;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}