/* -------------------------------------------------------------------------- *
 * Pagination styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;


.pagination {
  color: palette('green', 'light');
  font-weight: fontWeight('bold');
  margin: 40px 0;
  display: block;

  &__list {
    align-items: center;
    display: flex;
    justify-content: center;
    @extend %listReset;

    &--num {
      flex-grow: 1;
      justify-content: center;
    }
  }

  &__item {
    line-height: 1;
    margin: 0 3px;

    &:first-child {
      margin-left: 0;
      margin-right: 14px;
    }

    &:last-child {
      margin-left: 14px;
      margin-right: 0;
    }
  }

  &__arrow {
    color: palette('green', 'light');
    display: inline-block;
    padding: 10px;
    text-decoration: none;
    .icon {
      margin-top: -3px;
    }
  }

  &__link {
    color: palette('green', 'light');
    display: inline-block;
    padding: 10px;
    text-decoration: none;
    font-weight: fontWeight(bold);
  }

  &__text {
    color: palette('grey', 'medium');
    padding: 10px;
    text-decoration: none;
    font-weight: fontWeight(bold);
    .icon {
      margin-top: -3px;
    }
  }
}
