/* -------------------------------------------------------------------------- *
 * Card CTA *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.cardCta {
  @extend %grid;
  @extend %grid--small;
  grid-gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  &__item {
    background-color: white;
    border-radius: 10px;
    padding: 35px 20px 25px;
    position: relative;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h2 {
      margin: 0;
      line-height: 24px;
      font-size: toEm(20px);
      font-weight: fontWeight(medium);
    }
  }
    &__icon {
      margin-right: 20px;
      flex: 0 0 59px;
      img {
        width: 59px;
        height: 59px;
      }
      @media (max-width: 1050px){
        margin-right: 10px;
      }
    }

  .btn {
    margin: 0.5em 0.5em 0.5em 0;
  }

  &__infoButtonContainer {
    position: absolute;
    right: 15px;
    top: 8px;
  }

  &__infoButton {
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    margin-left: 12px;
    &:active {
      border: 0;
      outline: 0;
    }
    &:focus {
      border: 0;
      outline: 0;
    }
    img {
      width: 20px;
      height: 20px;
    }
    &.is-active,
    &:hover {
      opacity: 0.5;
    }
  }

  &__infoTarget {
    display: none;
    margin: 10px 0;
    padding: 10px;
    font-size: 14px;
    background-color: palette('green');
    color: white;
    border-radius: 10px;
    &.is-active {
      display: block;
    }
  }

  &--programForms {
    margin-top: 1em;
  }

  &__noFormsMessage {
    margin: 20px 0 40px;
  }
}