/* -------------------------------------------------------------------------- *
 * Row Layout Styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.row {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  &--reverse {
    flex-direction: row-reverse;
  }

  @media (max-width: toEm(700px)) {
    // display: block;
    // margin: 0;
  }

  &__col {
    flex-basis: calc(50% - var(--gutter) / 2);

    @media (max-width: toEm(700px)) {
      margin: 1rem 0;
    }
  }
}