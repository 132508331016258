/* -------------------------------------------------------------------------- *
 * Matrix Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.matrix {
  &__wysiwyg {
    a {
      color: palette('green');
      text-decoration: underline;

      &:hover {
        color: palette('green', 'light');
      }
    }
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      @extend %defaultHeading;
      margin: 40px 0 20px;
    }

    h3 {
      @extend %mediumSmallHeading;
      margin: 40px 0 20px;
    }

    h4 {
      @extend %smallHeading;
      margin: 40px 0 20px;
    }

    h5 {
      font-weight: bold;
      margin: 40px 0 20px;
    }

    ul {
      list-style-type: disc;
      ul {
        list-style-type: circle;
        ul {
          list-style-type: square;
        }
      }
    }

    ol {
      list-style-type: decimal;
      ol {
        list-style-type: lower-alpha;
        ol {
          list-style-type: lower-roman;
        }
      }
    }

    li {
      list-style: unset;
    }

    table {
      border: 1px solid palette('grey', 'light');
      margin: 1rem 0;

      thead {
        background-color: transparent;
      }

      tbody {
        background-color: transparent;
      }

      tr {
        border: 1px solid palette('grey', 'light');
      }

      th {
        padding: 10px 20px;
        border: 1px solid #b0afaa;
      }

      td {
        border: 1px solid palette('grey', 'light');
        padding: 10px 20px;
      }
    }

    figure {
      margin-top: 0;
      margin-bottom: 10px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &[style*='float:left'] {
        margin-left: 0 !important;
        margin-right: var(--gutter) !important;
      }

      &[style*='float:right'] {
        margin-left: var(--gutter) !important;
        margin-right: 0 !important;
      }

      &[style*='float'] {
        max-width: calc(50% - var(--gutter)) !important;
        margin-bottom: 2rem !important;

        @media (max-width: toEm(700px)) {
          float: none !important;
          margin: 2rem 0 !important;
          max-width: 100% !important;
        }
      }

      figcaption {
        font-size: toRem(14px);
        font-style: italic;
        color: palette('grey', 'caption');
        margin-top: 8px;
      }
    }
  }

  &__video {
    margin: 40px auto;
  }

  &__button {
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  &__videoContainer {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
  }

  &__videoIframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (max-width: 800px){
    &__wysiwyg h2 {
      font-size: 28px;
    }
  }
}