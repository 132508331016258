/* -------------------------------------------------------------------------- *
 * Cookie Consent Popup *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.employees {

  &__grid {
    @extend %grid;
    @extend %grid--large;
    margin: 50px 0;
  }

  &__name {
    color: palette('teal');
    font-size: toRem(18px);
    font-weight: fontWeight(bold);
    display: inline-block;
    line-height: 1.3em;
    margin: 10px 0 0;
  }

  &__tealTitle {
    color: palette('teal');
    font-size: toRem(18px);
    font-weight: fontWeight(bold);
    display: inline-block;
    line-height: 1.3em;
    margin: 10px 0 26px;
  }

  &__flex {
    display: flex;
    gap: 50px;
  }

  &__item {
    flex: 1 1 100%;
    &--photo {
      flex: 0 0 291px;
    }
  }

  @media (max-width: 700px){
    &__flex {
      flex-direction: column;
    }
  }
}