/* -------------------------------------------------------------------------- *
 * Testimonial Styles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.testimonial {
  max-width: 600px;
  margin: 2rem auto;

  &__content {
    font-size: toRem(28px);
    font-style: italic;
    line-height: 1.4;
    margin-bottom: 0;
  }

  &__author {
    text-align: right;
  }
}