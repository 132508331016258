@use 'baseline' as *;
@use 'placeholders' as *;

.freeform {
  margin-top: 10px;
  margin-bottom: 30px;
  .freeform-row .freeform-column .freeform-input {
    padding: 10px;
    border-radius: 17px;
    border: 1px solid palette('grey', 'light');
  }
  .freeform-row .freeform-column select.freeform-input {
    padding: 13px 6px;
  }
  button[type=submit] {
    background-color: palette('green', 'light');
    border-radius: 17px;
    border: none;
    color: white;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 9px 17px;
    text-decoration: none;
    font-weight: fontWeight(bold);
    font-size: toEm(16px);
    display: inline-block;

    border-top-left-radius: 0;
    -webkit-box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
    transition: all 0.3s ease-out;
    &:hover {
      color: white;
      background-color: palette('green');
    }
    &:focus {
      outline: 2px solid palette('green', 'forest');
      outline-offset: -2px;
    }
  }

  .freeform-column {
    h2 {
      @extend %defaultHeading;
      margin: 40px 0 20px;
    }

    h3 {
      @extend %mediumSmallHeading;
      margin: 40px 0 20px;
    }

    h4 {
      @extend %smallHeading;
      margin: 40px 0 20px;
    }

    h5 {
      font-weight: bold;
      margin: 40px 0 20px;
    }

    input[type='submit']{
      display: none;
    }
  }

  .ff-form-errors {
    margin-bottom: 20px;
  }
  .ff-form-success,
  .freeform-alert-success {
    margin-bottom: 20px;
    font-size: toRem(24px);
    line-height: 1.2em;
    margin-top: 40px;
  }

  @media (max-width: 500px){
    .freeform-row {
      display: block;
    }
  }
}