
@use 'baseline' as *;
@use 'placeholders' as *;

.brandPage {

  &__flex {
    display: flex;
  }
    &__itemPageHeader {
      flex: 1 1 50%;
      display: flex;
      justify-content: flex-end;
    }
    &__pageHeaderImage {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 40px;
      @media (max-width: 1300px){
        margin-top: -76px;
      }
    }
    &__itemImageHeader {
      flex: 1 1 50%;
      padding-bottom: 50px;
    }
      &__content {
        width: calc(1320px/2);
        padding-left: var(--gutter);
        padding-top: 20px;
        padding-bottom: 40px;
        padding-right: var(--gutter);
        @media (max-width: 1300px){
          width: 100%;
        }
      }

  &__brandVideos {
    @extend %block;
    @extend %block--greySwirls;
    padding-top: 0;
  }

  &__mainVideo {
    min-height: 600px;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    background-position: center, center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    @extend %container;
    max-width: 1350px;
    &--offsetTop {
      margin-top: -130px;
    }
  }

  &__videosList {
    @extend %listReset;
    display: flex;
    gap: var(--gutter);
    width: 100%;
    justify-content: center;
  }
    &__listItem {
      background-color: white;
      flex: 1 1 33.333%;
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      max-width: 395px;
    }
      &__itemContent {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: var(--gutter);
        padding-right: var(--gutter);
      }
      &__itemTitle {
        color: palette('green', 'light');
        font-size: toRem(18px);
        font-weight: fontWeight(bold);
        line-height: 1.1em;
      }
    &__itemImage {
      width: 100%;
      height: 268px;
      background-size: auto, cover;
      background-repeat: repeat, no-repeat;
      background-position: center, center;
      border-bottom-right-radius: 40px;
      &--mini {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }

  &__playButton {
    transition: all 0.3s ease-out;
    &--main {
      padding: 30px;
      margin-top: -100px;
    }
    &--thumb {
      padding: 30px;
    }
    &:hover {
      opacity: 0.5;
    }
  }

  &__producerVideos {
    padding: 60px 0;
  }
    &__header {
      text-align: center;
      margin-bottom: 60px;
      h2 {
        @extend %defaultHeading;
      }
    }
    &__description {
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
    }

  @media (min-width: 1366px){
    &__mainVideo {
      width: 1366px;
      height: 647px;
    }
  }

  @media (min-width: 1320px){
    &__pageHeaderImage {
      width: calc(1320px/2);
      border-bottom-right-radius: 40px;
    }
  }

  @media (max-width: 1300px){
    &__pageHeaderImage {
      margin-top: 0;
      border-top-left-radius: 40px;
      border-top-right-radius: 0;
    }
  }

  @media (max-width: 1160px) {
    &__itemContent {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 980px){
    &__videosList {
      display: block;
      max-width: 395px;
      margin: 0 auto;
    }
    &__listItem {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 900px){
    &__flex {
      display: block;
    }
    &__content {
      padding-bottom: 20px;
    }
    &__itemImageHeader {
      padding-left: var(--gutter);
      padding-right: var(--gutter);
      padding-bottom: 60px;
    }
    &__pageHeaderImage {
      margin-top: 0;
      width: 100%;
      border-radius: 20px;
    }
  }
}