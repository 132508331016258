/* -------------------------------------------------------------------------- *
 * Baseline Framework *
 * -------------------------------------------------------------------------- */

@use 'modules/baseline' as *;

/* -------------------------------------------------------------------------- *
 * Custom Modules *
 * -------------------------------------------------------------------------- */

@use 'modules/placeholders' as *;
@use 'modules/consent';
@use 'modules/btn';
@use 'modules/skip';
@use 'modules/notification';
@use 'modules/breadcrumbs';
@use 'modules/wysiwyg';
@use 'modules/button';
@use 'modules/cta';
@use 'modules/testimonial';
@use 'modules/row';
@use 'modules/accordion';
@use 'modules/footer';
@use 'modules/section';
@use 'modules/pageHeader';
@use 'modules/linkCard';
@use 'modules/main';
@use 'modules/miniCta';
@use 'modules/cardCta';
@use 'modules/homepageCarousel';
@use 'modules/block';
@use 'modules/dateTile';
@use 'modules/newsTile';
@use 'modules/featuredPages';
@use 'modules/news';
@use 'modules/contact';
@use 'modules/events';
@use 'modules/matrix';
@use 'modules/calendar';
@use 'modules/headerNav';
@use 'modules/employees';
@use 'modules/pagination';
@use 'modules/filter';
@use 'modules/freeform';
@use 'modules/search';
@use 'modules/career';
@use 'modules/tax';
@use 'modules/generic';
@use 'modules/tablesdynamic';
@use 'modules/faq';
@use 'modules/legend';
@use 'modules/burger';
@use 'modules/header';
@use 'modules/producers';
@use 'modules/tobii';
@use 'modules/brandPage';
@use 'modules/videoPlayer';
@use 'modules/alertMessage';
@use 'modules/zoomist';
@use 'modules/survey';

/* -------------------------------------------------------------------------- *
 * General Styles *
 * -------------------------------------------------------------------------- */

:root {
  @media (max-width: toEm(600px)) {
    --gutter: 20px;
  }
}

::selection {
  background-color: palette('blue');
}

html {
  background-color: white;
  color: palette('black');
  font-family: fontStack('default');
  line-height: settings('line_height');
}

body {
  font-size: toRem(16px);
  font-family: fontStack('default'); // bootstrap override
}

hr {
  clear: both;
  margin: 3em 0;
}

// Hide from view (modules/placeholders/layout)
.visuallyHidden {
  @extend %visuallyHidden;
}

// Standard container class (modules/placeholders/layout)
.container {
  @extend %container;
}

.grid {
  @include grid(3, (1000px: 2, 600px: 1));
}

// Inline IMG or SVG icon (modules/placeholders/text)
.icon {
  @extend %icon;
}

// Text colours mixin (modules/baseline/mixins)
@include colours;

// Common shared classes

h1 {
  @extend %largeHeading;

  @media (max-width: 800px){
    font-size: toRem(34px);
  }
  @media (max-width: 500px){
    font-size: toRem(30px);
  }
}

a {
  color: palette('green');
  text-decoration: none;

  &:hover {
    color: palette('green');
    text-decoration: underline;
  }
}



// UTILITY CLASSES

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-jcsb {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.width-33 {
  width: 33%;
}

// BORDERS

.border-radius--bottom-right-soft {
  border-radius: 12px 12px 40px 12px;
}


// PAGE SPECIFIC

.section__aside {

  a {
    color: palette('green');
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.news-item {
  display: flex;
}

.boxTest {
  height: 20px;
  width: 20px;
  border: 2px solid blue;
  display: none;

}

.boxTest.is-active {
  background: mediumvioletred;
  display: inline-block;
}

// Hide Calendar dev interface
.navbar {
  display: none;
}

.consentNotification__container {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
}

.anchorLink {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

@media print {
  .section__aside, .headerNav, .notification__container, .consent,.footer__socialLinks, .footer__menu, .homepageCarousel__media, .homepageCarousel__dotsWrapper {
    display: none !important;
  }
  .footer__top{
    color: black;
    background-color: transparent;
  }
  .footer__top a{
    color: black;
  }
  .main {
    margin-top: -100px !important;
    background-color: transparent;
  }
  .featuredPages{
    background-color: transparent;
    display: block;
  }
  .cardCta, .featuredPages__grid, .dateTile, .block__container {
    display: block;
  }
  .block__container{
    position: relative !important;
    break-inside:avoid !important;
  }
  .cardCta__item, .faq__question, .featuredPages__cell, .dateTile__item  {
    position: relative !important;
    break-inside:avoid !important;
  }
  .block--negativeMarginTop {
    display: block;
    margin-top: 0px !important;
  }
  .footer__list {
    width: 50% !important;
    margin: 0 auto !important;

  }
  .footer__top{
    border-top-color: black !important;
    border-top-width: 2px !important;
    border-top-style: solid !important;
    position: relative !important;
    break-inside:avoid !important;
  }
  .footer__list li:nth-child(2){
    float:right;
  }
  .tabledynamicholder{
    position: relative !important;
    break-inside:avoid !important;
  }
}
