/* -------------------------------------------------------------------------- *
 * CSS and SASS Variables *
 * -------------------------------------------------------------------------- */

/* --------------------------------- *
 * CSS Custom Properties (Variables) *
 * --------------------------------- */

:root {
  --gutter: 40px;
}

/* ------------------ *
 * Colour Palette Map *
 * ------------------ *
 * For all colours other than pure white and pure black
 * Access values using palette() in Functions.scss
 */

$palette_map: (
  'black': (
    'default': #414546,
    'lighter': #F3F3F2,
    'dark': #525252,
  ),
  'blue': (
    'default': #15BEF0,
  ),
  'green': (
    'default': #069748,
    'forest': #006B35,
    'light': #80C342,
  ),
  'grey': (
    'default': #414546,
    'medium': #707070,
    'light': #B0AFAA,
    'lighter': #f3f3f2,
    'caption': #8d8c8a,
  ),
  'yellow': (
    'default': #FEE938,
  ),
  'teal': (
    'default': #006078,
  ),
  'orange': (
    'default': #FFAB39,
  ),
);

/* ----------------- *
 * Site Settings Map *
 * ----------------- *
 * For common and shared settings and dimensions
 * Access values using settings() in Functions.scss
 */

$settings_map: (
  'line_height': 1.8,
  'root_font_size': 16px,
  'width': 1320px,
  'mobile_width': 1300px,
  'full_width_header': 1940px,
);

/* -------------- *
 * Font Stack Map *
 * -------------- *
 * For the font-family stacks used in the design
 * Access values using fontStack() in Functions.scss
 */

$font_stack_map: (
  'default': 'quatro, sans-serif',
  'monospace': 'monospace, monospace',
);

/* --------------- *
 * Font Weight Map *
 * --------------- *
 * For translating weight names to values
 * Access values using fontWeight() in Functions.scss
 */

$font_weight_map: (
  'thin': 100,
  'extra_light': 200,
  'light': 300,
  'normal': 400,
  'medium': 500,
  'semi_bold': 600,
  'bold': 700,
  'extra_bold': 800,
  'black': 900,
);

/* ------------ *
 * Z-Index List *
 * ------------ *
 * For z-index values that avoid the z-index arms race.
 * The higher the item is in the list, the higher the z-index value will be.
 * Access values using zIndex() in Functions.scss
 */

$z_index_list: (
  'headerNav',
  'top',
  'middle',
  'bottom',
);

$nav_breakpoint: 1200px;
