/* -------------------------------------------------------------------------- *
 * News Tiles *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.newsTile {
  @extend %listReset;
  display: flex;
  gap: var(--gutter);
  margin-top: 12px;

  @media (max-width: 800px){
    flex-direction: column;
  }

  &__item {
    flex: 1 1 100%;
    border-radius: 17px;
    border-top-left-radius: 0;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__category {
    font-weight: fontWeight(bold);
    text-transform: uppercase;
    line-height: 22px;
    margin-bottom: 12px;
  }

  &__details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: toRem(18px);
    font-weight: fontWeight(bold);
    color: palette('green');
    text-decoration: none;
    margin-bottom: 12px;
    line-height: 22px;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }

  &__postedOn {
    @extend %posted;
    margin-right: 20px;
  }
}