
@use 'baseline' as *;
@use 'placeholders' as *;

.tax {
  display: flex;
  flex-wrap: wrap;

  @extend %listReset;

  &__item {
    margin-bottom: 14px;
    margin-right: 10px;
  }

  &__link {
    align-items: center;
    color: palette('green', 'light');
    background: white;
    border-radius: 25px;
    display: flex;
    padding: 8px 15px;
    text-decoration: none;
    font-weight: fontWeight(bold);
    line-height: 1.3em;
    letter-spacing: .04em;

    &:hover {
      color: #fff;
      background-color: palette('green', 'light');
    }

    &.is-active {
      color: #fff;
      background-color: palette('green', 'light');

      &:hover {
        color: #fff;
        background-color: palette('green');
        border-color: #70969a;
      }
    }
    /*
    &--departments {
      border-color: palette('green', 'light');

      &:hover {
        background-color: palette('green', 'light');
      }

      &.is-active {
        color: #fff;
        background-color: palette('green', 'light');

        &:hover {
          color: #fff;
          background-color: #70969a;
          border-color: #70969a;
        }
      }
    }*/
  }
}
