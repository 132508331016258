/* -------------------------------------------------------------------------- *
 * Homepage Carousel *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

$width: settings(width);

.homepageCarousel {
  margin-top: -95px;
  h1, h2 {
    @extend %largeHeading;
  }
  &__item {
    display: flex;
    padding-top: 95px;
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;
    flex: 1 1 50%;
  }
    &__contentContainer {
      width: calc(#{$width}/2);
      padding-left: var(--gutter);
      padding-top: 20px;
      padding-bottom: 40px;
      padding-right: var(--gutter);
    }
    &__image {
      // margin-top: -95px;
      border-bottom-left-radius: 40px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      //min-height: 508px;
    }
  &__media {
    flex: 1 1 50%;
    img {
      //margin-top: -95px;
      width: 100%;
      height: auto;
      border-bottom-left-radius: 40px;
      border-top-left-radius: 0;
      margin-top: -40px;

      /*height: auto;
      left: 50%;
      max-width: none;
      min-width: 101%;
      min-height: 101%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: auto;
      z-index: 2;*/
    }
  }

  &__buttonGroup {
    margin-top: 12px;
  }

  &__dotsWrapper {
    position: absolute;
    margin-top: -108px;
    width: 100%;
  }

  &__dotsContainer {
    @extend %container;
  }

  &__dots {
    .slick-dots {
      text-align: left;
      position: relative;
      bottom: 0;
      top: 0;
      li {
        button {
          &:before {
            opacity: 1;
            color: palette('grey', 'light');
            font-size: 15px;
          }
        }
        &.slick-active {
          button {
            &:before {
              opacity: 1;
              color: palette('green', 'light');
            }
          }
        }
      }
    }
  }

  @media (min-width: settings('full_width_header')){
    &__image {
      width: calc(1320px/2);
      border-bottom-right-radius: 40px;
    }
  }

  @media (max-width: 1300px){
    &__media {
      img {
        margin-top: 0;
        border-top-left-radius: 40px;
      }
    }
  }

  @media (max-width: 900px){
    &__media {
      img {
        border-radius: 20px;
      }
    }
  }

  @media (max-width: 1200px){
    &__item {
      display: block;
    }
    &__content {
      padding-bottom: 0;
      justify-content: flex-start;
    }
    &__contentContainer {
      width: 100%;
      max-width: 800px;
    }
    &__media {
      padding-left: var(--gutter);
      padding-right: var(--gutter);
    }
    &__image {
      margin-top: 0;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      border-bottom-left-radius: 40px;
      border-top-left-radius: 0;
    }
    &__dotsWrapper {
      position: relative;
      margin-top: 0;
      height: 50px;
    }
    &__dots {
      .slick-dots {
        text-align: center;
      }
    }
  }

  @media (max-width: 800px){
    &__item {
      flex-direction: column;
    }
    h1, h2 {
      font-size: toRem(34px);
    }
  }
}