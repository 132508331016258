/* -------------------------------------------------------------------------- *
 * WYSIWYG Editor Styles *
 * -------------------------------------------------------------------------- *
 * Styles for the markup being output by the Redactor WYSIWYG plugin. As we
 * don't have full control over this markup, the selectors here do not follow
 * the BEM naming conventions.
 */

@use 'baseline' as *;
@use 'placeholders' as *;

.wysiwyg {

  &__intro {
    color: palette('green');
    font-size: toEm(20px);
    line-height: 30px;
  }
}
