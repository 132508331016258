/* -------------------------------------------------------------------------- *
 * Mini CTA Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.miniCta {
  display: flex;
  margin-top: 35px;
  margin-bottom: 35px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  min-height: 243px;

  &__heading {
    @extend %smallHeading;
  }

  &__image {
    border-bottom-right-radius: 40px;
    flex: 0 0 356px;
    position: relative;
    overflow: hidden;
    @media (max-width: 1100px){
      flex: 0 0 256px;
    }

    img {
      height: auto;
      left: 50%;
      max-width: none;
      min-width: 101%;
      min-height: 101%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: auto;
      z-index: 2;
    }
  }

  &__content {
    flex-basis: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  table {
    border: 1px solid palette('grey', 'light');
    margin: 1rem 0;

    thead {
      background-color: transparent;
    }

    tbody {
      background-color: transparent;
    }

    tr {
      border: 1px solid palette('grey', 'light');
    }

    th {
      padding: 10px 20px;
      border: 1px solid #b0afaa;
    }

    td {
      border: 1px solid palette('grey', 'light');
      padding: 10px 20px;
    }
  }

  @media (max-width: 600px){
    flex-direction: column;
    &__image,
    &__content {
      flex: 1 1 100%;
    }
    &__image {
      min-height: 240px;
    }
  }
}