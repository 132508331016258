
@use 'baseline' as *;
@use 'placeholders' as *;

$width: settings(width);

.header {

  &__item {
    display: flex;
    width: 100%;
    max-width: $width;
    margin: 0 auto;
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
    flex: 1 1 60%;
  }
    &__contentContainer {
      width: 100%;
      max-width: calc(#{$width}*0.6);
      padding-left: var(--gutter);
      padding-top: 20px;
      padding-bottom: 40px;
      padding-right: var(--gutter);
      .breadcrumbs__container {
        padding-left: 0;
      }
    }
  &__media {
    flex: 1 1 40%;
  }
    &__image {
      margin-top: -58px;
      border-bottom-left-radius: 40px;
      @media (max-width: 1300px){
        margin-top: 0;
      }
    }

  @media (min-width: settings('full_width_header')){
    &__image {
      width: calc(#{$width}*0.4);
      border-bottom-right-radius: 40px;
    }
  }

  @media (min-width: $width){
    &__image {
      border-bottom-right-radius: 40px;
    }
  }

  @media (max-width: 1300px){
    &__content {
      flex: 1 1 50%;
    }
    &__media {
      flex: 1 1 50%;
    }
    &__image {
      border-top-left-radius: 40px;
    }
  }

  @media (max-width: 1050px){
    &__item {
      flex-direction: column;
    }
      &__content {
        justify-content: flex-start;
        padding-bottom: 0;
      }
      &__media {
        padding-left: var(--gutter);
        padding-right: var(--gutter);
        padding-bottom: 60px;
      }
      &__image {
        margin-top: 0;
        width: 100%;
        border-radius: 20px;
      }
  }

  @media (max-width: 755px){
    &__image {
    }
  }

  @media (max-width: 430px){
    &__image {
    }
  }
}