@use 'baseline' as *;
@use 'placeholders' as *;

.faq {
  &__header {
    margin-bottom: 40px;
  }

  &__container {
    @extend %fullWidthContainer;
  }

  &__content {
    @extend %container--genericContent;
  }

  &__contentContainer {
    @extend %block;
    @extend %block--bottomPaddingOnly;
  }

  &__item {
    margin: 4em 0;
  }

  &__itemHeading {
    @extend %mediumSmallHeading;
  }

  &__set {
    margin: 2em 0;
    display: flex;
    flex-direction: column;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    transition: all 0.15s ease-out;
    .cls-1faq {
      transition: all 0.15s ease-out;
    }
    .cls-2faq {
      transition: all 0.15s ease-out;
    }
  }

  &__question {
    align-self: flex-end;
    color: white;
    background-color: palette('grey', 'light');
    font-size: toRem(20px);
    font-weight: fontWeight(semi_bold);
    font-style: italic;
    padding: 20px;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    line-height: 1.4em;
    text-align: left;
    display: flex;
    align-items: center;

    &.is-active {
      .faq__icon {
        transform: rotate(45deg);
        .cls-1faq {
          fill: palette('green');
        }
        .cls-2faq {
          stroke: white;
        }
      }
    }

    &:after {
      content: url('/images/faq-tail.svg');
      position: absolute;
      bottom: -28px;
      right: -1px;
      width: 28px;
      height: 28px;
    }
  }

  &__answer {
    display: none;
    &.is-active {
      display: block;
    }
  }

  &__tag {
    color: white;
    background-color: palette('green');
    font-size: toRem(20px);
    padding: 20px var(--gutter);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-block;
    margin-bottom: 30px;
    position: relative;
    line-height: 1.4em;
    &:after {
      content: url('/images/faq-tail-green.svg');
      position: absolute;
      bottom: -28px;
      left: 0;
      width: 28px;
      height: 28px;
    }
  }

  &__answerWrapper {
    padding-left: var(--gutter);
  }

  @media (max-width: 600px){
    &__sets {
      padding-left: 0;
    }
  }
}