
@use 'baseline' as *;
@use 'placeholders' as *;

.career {
  &__item {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 20px;
  }
  &__title {
    @extend %smallHeading;

    a {
      @extend %textLink;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__info {
    text-transform: uppercase;
    font-weight: fontWeight(bold);
  }
  &__details {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn {
      margin-bottom: 0;
    }
  }
  &__list {
    @extend %listReset;
  }
  &__postDate {
    @extend %posted;
    margin: 0 20px 0 0;
  }
}