
@use 'baseline' as *;
@use 'placeholders' as *;

.search {
  &__container {
    @extend %fullWidthContainer;
  }

  &__content {
    @extend %container--genericContent;
    header {
      h1 {
        margin-bottom: 1rem;
      }
    }
  }

  &__list {

    li {
      list-style: disc;
      margin: 0.5rem 0;
    }
  }

  &__headingLink {
    font-weight: fontWeight(bold);
    font-size: 18px;
  }

  &__description {
    font-size: 14px;
    margin-top: 0.5rem;
  }
}