.zoomist-container {
  position: relative;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 20px 0;
}

.zoomist-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #f4f4f4; }

.zoomist-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none !important;
  max-height: none !important;
  pointer-events: none; }

.zoomist-slider {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0 0 5px 0; }

.zoomist-slider-main {
  position: relative;
  display: flex;
  justify-content: flex-start; }
.zoomist-slider-main:hover .zoomist-slider-bar {
  background-color: #aaa; }
.zoomist-slider-main:hover .zoomist-slider-button::before {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); }
.zoomist-slider-main.zoomist-slider-horizontal {
  align-items: flex-start;
  width: 150px;
  padding: 20px 0;
  margin: 0 20px; }
.zoomist-slider-main.zoomist-slider-horizontal .zoomist-slider-bar {
  top: calc( 50% - 1px);
  left: 0;
  width: 100%;
  height: 2px; }
.zoomist-slider-main.zoomist-slider-vertical {
  align-items: flex-end;
  height: 150px;
  padding: 0 20px;
  margin: 20px 0; }
.zoomist-slider-main.zoomist-slider-vertical .zoomist-slider-bar {
  top: 0;
  left: calc( 50% - 1px);
  width: 2px;
  height: 100%; }

.zoomist-slider-bar {
  display: block;
  position: absolute;
  z-index: 0;
  border-radius: 1px;
  background-color: #ccc;
  transition: background-color .3s; }

.zoomist-slider-button {
  display: block;
  position: relative;
  z-index: 1;
  width: 0 !important;
  height: 0 !important; }
.zoomist-slider-button::before {
  position: absolute;
  display: block;
  content: '';
  left: -5px;
  top: -5px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  transition: box-shadow .3s; }

.zoomist-zoomer {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  border-radius: 0 0 0 5px;
  overflow: hidden; }

.zoomist-in-zoomer, .zoomist-out-zoomer {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color .3s; }
.zoomist-in-zoomer:hover, .zoomist-out-zoomer:hover {
  background-color: rgba(255, 255, 255, 0.9); }
.zoomist-in-zoomer svg, .zoomist-out-zoomer svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  fill: #333; }

.zoomist-zoomer-disable {
  pointer-events: none;
  opacity: .6; }
