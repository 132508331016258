/* -------------------------------------------------------------------------- *
 * Date Tile Styling *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.dateTile {
  @extend %listReset;
  display: flex;
  gap: var(--gutter);
  margin-top: 12px;
  justify-content: space-between;

  @media (max-width: 800px){
    flex-direction: column;
  }

  &__item {
    //flex: 1 1 100%;
    width: 100%;
    border-radius: 17px;
    overflow: hidden;
    a {
      display: flex;
      text-decoration: none;
      height: 100%;
      &:hover {
        color: white;
        .dateTile__info {
          text-decoration: underline;
        }
      }
    }
  }

  &__deadline {
    margin-bottom: 10px;
    line-height: 1.3rem;
    font-size: toRem(18px);
    font-weight: fontWeight(bold);
  }

  &__iconCalendar {
    position: relative;
    top: -2px;
    svg {
      width: 20px;
    }
  }

  &__date {
    flex: 0 0 70px;
    padding: 10px;
    color: white;
    background-color: palette('blue');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: toEm(30px);
    font-weight: fontWeight(bold);
    line-height: 23px;
    div {
      font-size: toRem(14px);
    }
  }

  &__info {
    flex: 1 1 100%;
    padding: 10px 12px;
    color: palette('blue');
    background-color: white;
    display: flex;
    align-items: center;
    font-size: toEm(16px);
    font-weight: fontWeight(bold);
    line-height: 20px;
    text-decoration: none;
  }
}