/* -------------------------------------------------------------------------- *
 * Breadcrumb Links *
 * -------------------------------------------------------------------------- */

@use 'baseline' as *;
@use 'placeholders' as *;

.breadcrumbs {

  &__container {
    @extend %container;
  }

  &__list {
    display: flex;
    @extend %listReset;
    a {
      color: palette('grey');
    }
  }

  &__search {
    text-transform: uppercase;
    color: palette('green');
    span {
      margin-right: 6px;
      margin-left: 6px;
      position: relative;
      top: -2px;
    }
  }

  &__item {
    margin-right: 5px;
    min-width: 0;
    padding-right: 15px;
    position: relative;

    &:after {
      content: '>';
      position: absolute;
      right: 0;
      top: -2px;
      font-size: toRem(12px);
    }

    &:first-child {
      flex-shrink: 0;
    }

    &:last-child {
      flex-shrink: 2;
      margin-right: 0;
      padding-right: 0;

      &:after {
        display: none;
      }
    }

    @media (max-width: toEm(600px)) {
      display: none;
      padding-left: 55px;
      padding-right: 0;
      margin-right: 0;

      &:before {
        content: 'BACK TO: ';
        display: inline-block;
        left: 0;
        position: absolute;
        top: 0;
        width: 55px;
        font-size: toRem(12px);
      }

      &:after {
        display: none;
      }

      &:first-child {
        padding-left: 55px;
      }

      &:nth-last-child(2) {
        display: block;
      }
    }
  }

    &__link {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: toRem(12px);
      text-transform: uppercase;

      &--active {
        text-decoration: none;
        opacity: 0.5;

        &:hover {
          text-decoration: underline;
        }
      }
    }
}
