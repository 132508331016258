
@use 'baseline' as *;
@use 'placeholders' as *;

.alertMessage {
  border: none;
  color: white;
  text-decoration: none;
  font-weight: fontWeight(bold);
  display: inline-flex;
  align-items: flex-start;
  background-color: palette('blue');
  border-radius: 17px;
  border-bottom-left-radius: 0;
  padding: 5px 15px;
  font-size: toEm(12px);
  -webkit-box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
  box-shadow: -1px 3px 6px 1px rgba(0,0,0,0.2);
  position: relative;
  z-index: 1;
  max-width: calc(1320px/2);
  a {
    color: white;
    text-decoration: underline;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
  .btn__icon {
    top: 0;
  }
  &__content {
    display: inline-block;
  }
}