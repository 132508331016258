/* -------------------------------------------------------------------------- *
 * Placeholder Classes for Layout *
 * -------------------------------------------------------------------------- */

@use '../baseline' as *;

// Standard container class
%container {
  margin-left: auto;
  margin-right: auto;
  max-width: settings('width');
  padding-left: var(--gutter);
  padding-right: var(--gutter);

  &--genericContent {
    width: 100%;
    max-width: 900px;
  }
}

// Remove margins from child elements
%crushMargins {
  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

// Remove default styles from list elements
%listReset {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Hide from view but not from screen readers
%visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Grid layout
%grid {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  @extend %listReset;

  @media (max-width: toEm(1100px)) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: toEm(1000px)) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: toEm(1000px)) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: toEm(600px)) {
    grid-template-columns: repeat(1, 1fr);
  }

  &--large {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: toEm(1280px)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: toEm(450px)) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--small {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: toEm(800px)) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &--list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  &__cell {
    align-self: stretch;

    &--large {
      grid-column: span 2;

      @media (max-width: toEm(550px)) {
        grid-column: span 1;
      }
    }

    &--clientStory {
      min-height: 960px;
    }
  }

  &__producers {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

  &__producers--small {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}

%block {
  padding: 40px 0;

  &--greySwirls {
    background: url('/images/pattern-grey-swirls.png') palette('grey');
  }

  &--grey {
    background-color: palette('grey', 'lighter');
  }

  &--bottomPaddingOnly {
    padding: 0 0 40px;
  }
}

%fullWidthContainer {
  @extend %container;
  margin-top: 20px;
  margin-bottom: 40px;
}
